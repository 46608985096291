import { decorate, observable, computed, action } from "mobx";

class MapViewLayer {
  mapView;
  geoQuestionId;
  types = [];
  geoQuestion;
  activeType = "";
  _title = null;

  constructor(mapView, conf, geoQuestion) {
    this.mapView = mapView;
    this.geoQuestionId = conf.geoQuestionId;
    this.types = conf.types;
    this.activeType = conf.default ? conf.default : conf.types[0];
    this.geoQuestion = geoQuestion;
    if (conf.title) this._title = conf.title;
  }

  get data() {
    let result = [];
    this.mapView.store.filteredUsers.forEach((user) => {
      result.push(...user.getGeoAnswers(this.geoQuestionId));
    });

    if (this.geoQuestion.textFilters.length) {
      result = result.filter((a) => {
        for (let i = 0; i < this.geoQuestion.textFilters.length; i++) {
          if (!this.geoQuestion.textFilters[i].matchesText(a.flatDescription)) {
            return false;
          }
        }
        return true;
      });
    }
    return result;
  }

  get visible() {
    if (this.mapView.spatialFilter !== null) {
      if (this.mapView.spatialFilter.active) {
        return true;
      }
      return this.mapView.spatialFilter.layer === this;
    }

    return true;
  }

  toggleActiveType(type) {
    //only show one heatmap at a time
    if (this.activeType === type) {
      this.activeType = "off";
      return;
    }

    if (type === "heatmap") {
      this.mapView.layers.forEach((layer) => {
        if (layer.activeType === "heatmap") {
          layer.activeType = "markers";
        }
      });
    }
    this.mapView.popup = null;
    this.activeType = type;
  }

  get title() {
    if (this._title) return this._title;
    let q = this.mapView.store.questionStore.getGeoQuestion(this.geoQuestionId);
    if (q) {
      return q.key;
    }
    return "unknown";
  }

  get hasSpatialFilter() {
    return this.types.includes("markers");
  }
}

export default decorate(MapViewLayer, {
  activeType: observable,
  data: computed,
  toggleActiveType: action,
  title: computed,
  visible: computed,
  hasSpatialFilter: computed,
});
